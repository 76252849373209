@mixin textfield-placeholder($color: #a3a9b5) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin image-2x($image, $width: 100%, $height: 100%) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin text-base($font-size, $color) {
  font-family: $base-font;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-size: $font-size;
  color: $color;
}

@mixin create-class($attribute: "margin-top", $list: $listMarginTop) {
  @each $name, $px in $list {
    .#{$name} {
      #{$attribute}: $px;
    }
  }
}
