.wrapper-auth {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  position: relative;
  background-image: url("../../../assets/images/background/bg-auth.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

@media screen and (max-width: 475px) {
  .wrapper-auth {
    padding: 5px;
  }
}
