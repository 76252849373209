@import "assets/css/variables";
$text: #cce8ff;
$bg-hover: #1d2856;

.COMPLETED-status {
  color: $success-color;
}
.PENDING_REVIEW-status,
.PENDING_VERIFY_CODE-status {
  color: $error-text;
  border: 1px solid $wallet-border;
  border-radius: 13px;
  padding: 5px;
  max-width: 200px;
  text-align: center;
}
.FAILED-status,
.CANCEL-status {
  color: $error-text;
}
.PENDING-status,
.VERIFIED-status {
  span {
    border: 1px solid #eeeeee42;
    color: #ff7a27;
    padding: 0px 18px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.PROCESSING-status {
  color: #004078;
}

.pending-modal {
  .ant-modal-confirm-btns {
    padding-bottom: 17px;
  }
}
.wrapper-status-btn {
  display: flex;
  justify-content: left;
}
.cancel-action {
  border: 1px solid #eeeeee42;
  color: #e1e1e1;
  padding: 0px 18px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.border-bottom-title {
  border-bottom: 2px solid #4b6583;
  padding-bottom: 7px;
  font-size: 16px;
}

.wallets-list-container {
  .ant-table,
  .ant-table-thead > tr > th {
    background: transparent;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    color: $text;
    font-size: 14px;
    white-space: nowrap;
    background: transparent;
    border-bottom: 1px dashed #f0f0f0;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: $bg-hover;
  }
  .ant-table tr th:first-child,
  .ant-table tr td:first-child {
    padding-left: 0;
  }
  .table-wrapper {
    .section-title {
      color: #008aff;
      max-width: 100%;
    }
    .ant-table-wrapper {
      margin-top: 20px;
    }
    .btn-load-more {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .btn-primary {
        width: unset;
        display: flex;
        min-height: 40px;
        border-radius: 6px;
        padding-left: 30px;
        padding-right: 30px;
        align-items: center;
      }
    }
  }
  .ant-empty-description {
    color: $text;
  }
}
