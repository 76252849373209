@import "assets/css/variables";

.private-layout-container {
  min-height: calc(100vh - 10px);
}
.content-layout {
  width: 100%;
  padding-bottom: 100px;
  position: relative;
  background: $bg-content;
  overflow: hidden;

  .networks-container {
    width: 100%;

    .networks-content {
      width: 100%;
    }
  }
}
@media (min-width: 767px) {
  .content-layout {
    padding: 20px 40px 35px;
    padding-bottom: 100px;
  }
}

@media (max-width: 830px) {
  .content-layout {
    padding: 20px;
    padding-bottom: 100px;
  }
}

@media (max-width: 480px) {
  .content-layout {
    padding: 30px 8px;
    padding-bottom: 100px;
  }
}
