@import "assets/css/variables";
@import "assets/css/col.scss";

$listMarginTop: (
  mt-3: 3px,
  mt-5: 5px,
  mt-10: 10px,
  mt-20: 20px,
  mt-30: 30px,
  mt-40: 40px,
  mt-50: 50px,
  mt-60: 60px,
);

$listMarginBottom: (
  mb-3: 3px,
  mb-10: 10px,
  mb-15: 15px,
  mb-20: 20px,
  mb-30: 30px,
  mb-40: 40px,
);

$listMarginLeft: (
  ml-3: 3px,
  ml-5: 5px,
  ml-10: 10px,
  ml-20: 20px,
  ml-30: 30px,
  ml-40: 40px,
);
$listMarginRight: (
  mr-1: 1px,
  mr-2: 2px,
  mr-3: 3px,
  mr-5: 5px,
  mr-7: 7px,
  mr-8: 8px,
  mr-10: 10px,
  mr-15: 15px,
  mr-20: 20px,
  mr-30: 30px,
  mr-40: 40px,
);

$listWidth: (
  w-10: 10px,
  w-20: 20px,
  w-30: 30px,
  w-40: 40px,
  w-10pc: 10%,
  w-20pc: 20%,
  w-30pc: 30%,
  w-100pc: 100%,
  w-10vw: 10vw,
  w-20vw: 20vw,
  w-30vw: 30vw,
);

$listHeight: (
  h-10: 10px,
  h-20: 20px,
  h-30: 30px,
  h-40: 40px,
);

$listPositionText: (
  t-center: center,
  t-right: right,
  t-left: left,
);

$listBorderRadius: (
  border-radius-5: 5px,
  border-radius-10: 10px,
  border-radius-15: 15px,
  border-radius-20: 20px,
);

@include create-class($attribute: "text-align", $list: $listPositionText);
@include create-class($attribute: "margin-top", $list: $listMarginTop);
@include create-class($attribute: "margin-bottom", $list: $listMarginBottom);
@include create-class($attribute: "margin-left", $list: $listMarginLeft);
@include create-class($attribute: "margin-right", $list: $listMarginRight);
@include create-class($attribute: "width", $list: $listWidth);
@include create-class($attribute: "height", $list: $listHeight);
@include create-class($attribute: "border-radius", $list: $listBorderRadius);

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../fonts/HelveticaNeue.ttf") format("truetype");
}

.ant-layout {
  header {
    background: $menu-mobile-text !important;
  }
  color: $text-3;
  background: #f8f6f6;

  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom center;
}
body {
  font-family: $base-font;
  font-size: 16px;
}

#root,
.App {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: rgba(0, 38, 63, 0.2) 0px 0px 5px inset;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #004078;
  border-radius: 8px;
}
/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

// Custom Class
.progress-wrapper {
  height: 0.2rem;
  .loader-60devs {
    z-index: 10051;
  }
  .loader-60devs .loader-60devs-progress {
    height: 0.2rem;
    width: 100%;
    background-color: $menu-mobile-background;
  }
  .loader-60devs::before {
    display: none;
  }
}

// label custom css
.label-custom {
  margin-bottom: 9px;
  color: rgba(0, 0, 0, 0.85);
  .label-require::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: $base-font;
    line-height: 1;
    content: "*";
  }
}

.ant-form-item-label label {
  color: #fff;
  font-size: 16px;
}

// loading
.loader {
  .ant-spin-dot {
    font-size: 40px;
  }
  position: fixed;
  background: rgba(181, 188, 199, 0.4);
  z-index: 9999;
  margin: 0 auto;
  width: 100%;
  height: 100vh;

  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  align-items: center;
}

.image-loading {
  width: 207.35px;
}

.image-preloader {
  width: 150px;
  animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
}

.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.d-flex-between {
  display: flex;
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.color-primary {
  color: #004078;
}
.color-secondary {
  color: #cce8ff;
}
.color-tertiary {
  color: #002748;
}
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.center {
  text-align: center;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
* Modal
*/
.ant-modal-root {
  z-index: 999;
}
.ant-modal-content {
  border: $modal-border;
  border-radius: $modal-border-radius;
  background: $modal-bg;
  overflow: hidden;
}
.ant-modal-header {
  background: transparent;
  border-bottom: $modal-border-line-1;
}
.ant-modal-body {
  background: transparent;
  padding: 25px 24px 14px;
  .ant-select-selector,
  input {
    height: 50px;
    border-radius: 4px;
  }
  label,
  .label-form {
    color: $modal-body-text;
    font-size: 18px;
    margin-bottom: 7px;
  }
  .btn-confirm {
    margin-top: 17px;
    text-align: center;
    border-radius: 6px;
    background-color: $modal-header-text;
    box-shadow: 0 0 15px $modal-header-text;
    cursor: pointer;
    button {
      padding: 2px 10px;
      color: #fff;
      height: 100%;
      font-size: 27px;
      background: unset;
      border: unset;
      &:valid,
      &:hover,
      &:active {
        overflow: hidden;
      }
    }
  }
}
.ant-modal-footer {
  background: transparent;
  padding: 10px 24px 24px 24px !important;
  cursor: pointer;
  border: unset;
  button {
    color: #fff;
    background-color: $modal-header-text;

    font-size: inherit;
    text-align: center;
    padding: 0;
    height: unset;
    border: unset;
    outline: unset;
    cursor: pointer;
    &:active,
    &:focus,
    &:valid,
    &:hover {
      color: inherit;
      background-color: inherit !important;
    }
  }
  .modal-footer-label {
    color: #fff;
    font-size: 23px;
    text-align: center;
    padding: 0px 0px;
    margin-top: 0px !important;
  }
}
.ant-form-item-explain-error {
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
}

/*
* Modal Confirm
*/
.custom-confirm {
  .ant-modal-confirm-title {
    color: $modal-header-text;
  }
  .ant-modal-body {
    padding: 20px !important;
  }
  .ant-modal-confirm-content {
    color: $modal-header-text;
  }
  .ant-modal-confirm-btns {
    button {
      min-width: 80px;
      border-radius: 6px;
    }
  }
}

.ant-input {
  border-radius: 4px;
}

.text-success-color {
  color: $success-color;
}

// hidden on mobile
@media only screen and (max-width: 1170px) {
  .hidden-on-mobile {
    display: none;
  }
}

// Antd tag
.ant-tag {
  // background: transparent;
  border-radius: 5px;
}
.share-code-tag {
  color: #fff;
  padding: 4px 15px;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  border-radius: 13px;
  background: #3175c4;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 0 rgb(173, 173, 173);
  }
}
.activated-tag {
  color: #fff;
  padding: 4px 15px;
  display: inline-block;
  min-width: 90px;
  text-align: center;
  border-radius: 13px;
  background: #31c45b;
  &:hover {
    box-shadow: 0 1px 0 rgb(173, 173, 173);
  }
}

.amount-in {
  color: #389e0d;
  background-color: #f6ffed;
  font-weight: 700;
  font-size: 0.60938rem;
  line-height: 1.7;
  padding: 0 0.5rem;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.35rem;
  text-transform: uppercase;
  width: 37px;
}
.amount-out {
  color: #ffffff;
  background-color: rgba(219, 154, 4, 0.2);
  font-weight: 700;
  font-size: 0.60938rem;
  line-height: 1.7;
  padding: 0 0.5rem;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.35rem;
  text-transform: uppercase;
  width: 37px;
}

.table-ant-custom {
  .ant-table-content {
    overflow: auto;
  }
}

.pega-content-title {
  font-family: $base-font;
  font-size: 30px;
  color: #fff;
}

.transfer-custom {
  .ant-modal-content {
    border: none;
  }
}

.ant-card-body {
  padding: 0;
}
.ant-card-head {
  border-bottom: none;
  min-height: 35px;
}
.ant-card-bordered {
  border: none;
}
.pointer {
  cursor: pointer !important;
}
.pointer-not-allowed {
  cursor: not-allowed !important;
}
.flex-betwen {
  display: flex;
  justify-content: space-between;
}
.box-err {
  padding: 15px 5px 0;
  color: yellow;
  font-size: 14px;
  text-align: center;
}
hr.hr {
  border-color: rgba($color: red, $alpha: 0.2);
}

@media (min-width: 1200px) {
  .col-xl-5 {
    display: block;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.ant-tooltip {
  display: none !important;
}
