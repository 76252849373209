@import "./mixins/mixins";
@import "./mixins/breakpoints";

$base-font: "HelveticaNeue", sans-serif;

$text-primary-color: #5b337e;
$success-color: #52c41a;

// menu
$menu-header-text: #002aa4;
$menu-header-background: linear-gradient(to right, #fff174, #ffc775);
$menu-desktop-text: #ffffff;
$menu-desktop-background: linear-gradient(to top, #a9d7ff, #5c84fc);
$menu-mobile-text: #ffffff;
$menu-mobile-background: linear-gradient(to top, #a9d7ff, #5c84fc);

//  Background content
$bg-content: #f8f8f8;

$gradient-ping-color: rgba(193, 57, 69, 0.5);
$gradient-violet-color: rgba(142, 17, 35, 0.5);
$gradient-btn-ping-color: #f54754;
$gradient-btn-violet-color: #a90d23;

$main-color: #ff9a33;
$main-color-table: #fff;
$main-hover-color: #fff;
$main-font-size-table: 14px;

$main-bg-block: #004078;
$main-text-block: #ffd139;

$table-bordered-color: #7c5e65;
$background-modal: #00000030;
$main-purple: #650100; //text

/* ======> box-shadow <======== */
$box-shadow: 0 0 5px 1px #858383;
$box-shadow-1: 0 0 8px 0px #cab3b3;

/* ======> Modal <======*/
$modal-bg: linear-gradient(to top, #ffc775, #ffec74);

$modal-header-text: #13273c;
$modal-body-text: #13273c;
$modal-footer-text: #13273c;

$modal-close-x: #13273c;

$modal-border: unset;
$modal-border-line-1: 1px solid #13273c;
$modal-border-line-2: none;

$modal-border-radius: 15px;

$modal-button-text: #ffd139;
$modal-button-bg: #004078;
$modal-button-border: unset;
$modal-button-shadow: unset;

/* ======> Text <======*/
$text: #ff9a33;
$text-2: #fff;
$text-3: #000000;

/* ======> Background <======*/

/* ======> Card <======*/
$bg-card: linear-gradient(to top, #596dff, #5b9cfd);
$main-bg-card: rgba(249, 44, 65, 0.2);
$main-bg-card-2: rgba(249, 44, 65, 0.2);
$main-bg-input: rgba(
  $color: #f92c41,
  $alpha: 0.3,
);

$main-radius-card: 5px;

$wallet-border: #efefef;
$wallet-background: #9f2c4330;

$main-white: #fff;

$box-shadow-card: 0 0 5px 1px #c7c7c7;
/* ======> Table <======*/
$table-text: #fff;
$table-bg: linear-gradient(to top, #596dfe, #5a9afd);
$table-box-shadow: 0 0 5px 1px #d0d0d0;

/* ======> Button <======*/
$button-text: #ffd139;
$button-bg: #004078;
$button-border: none;
$button-fontSize: 17px;
$button-active-text: #fff;
$button-active-bg: #fff;

$btn-gradient: linear-gradient(180deg, #009ae8 0%, #002aa4 100%);
$bg-gradient: rgb(85, 36, 81)
  linear-gradient(90deg, rgba(85, 36, 81, 1) 32%, rgba(128, 51, 104, 1) 52%, rgba(106, 43, 93, 1) 63%, rgba(82, 35, 80, 1) 77%);

$background-input: #15171c;

$input-color: #7b7b7b;

$color-card: #00000030;
$error-text: #ff7a27;
$stake-title: #00000030;

/* ======> Input <======*/
$input-text: #ff7a27;
$input-bg: #15171c;
$input-label: #000000;
$input-border: #ff7a27;
$input-placeholder: #ff7a27;
$input-boxShadow: 0 0 1px 2px
  rgba(
    $color: $input-border,
    $alpha: 0.2,
  );

$input-text-2: #ffffff;
$input-bg-2: #15171c;
$input-label-2: #000000;
$input-border-2: #ff7a27;
$input-placeholder-2: #ff7a27;

/* =====> Tree color <===== */
$line-ref-color: #002748;
$box-ref-box-shadow: 10px 10px 25px -10px rgba(0, 0, 0, 50%);
$box-ref-border: #d9d9d9;

// level box ref color
$box-ref-color: linear-gradient(to right, #596dfd, #5997fd);
$box-ref-color-lv1: linear-gradient(to right, #596dfd, #5997fd);
$box-ref-color-lv2: linear-gradient(to right, #5d85fc, #a5d3fe);
$box-ref-color-lv3: linear-gradient(to right, #81afff, #9dcbf9);
