@import "assets/css/variables";

.menu-collapse-icon {
  color: #004078;
  margin: auto 0;
  margin-left: 10px;
}
.header-container.ant-layout-header {
  background: $menu-header-background !important;
  line-height: 0 !important;
  height: 50px !important;
  padding: 0 !important;
  .ant-progress-text {
    display: none;
  }
  .ant-progress-inner {
    color: #fff;
  }
  .ant-progress-outer {
    width: 300px;
    margin-left: 216px;
    .ant-progress-inner,
    .ant-progress-success-bg,
    .ant-progress-bg {
      border-radius: 0;
    }
    .ant-progress-success-bg {
      background-color: #1d5ece !important;
    }
    .ant-progress-bg {
      background-color: #3bde41 !important;
    }
    span {
      font-size: 11px !important;
      position: absolute;
      padding: 0 2px;
    }
    .right-progress {
      right: 0;
    }
  }
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: #fff;
    .icon-shopping {
      font-size: 24px;
    }
  }
  .col-left {
    display: flex;
    .content-nav {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
  .col-right {
    display: flex;
    align-items: center;
    height: 100%;
    @include media-breakpoint-down(sm) {
      font-size: 15px;
    }
  }

  .title-header {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    color: #fff;
    float: left;
    span {
      font-size: 18px;
    }
  }
  .image-avatar {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    background-repeat: no-repeat;
  }
}
@media only screen and (max-width: 998px) {
  .title-header {
    display: none !important;
  }
}

.box-interest {
  padding: 6px 25px;
  background-color: rgba($color: #ffffff, $alpha: 0.1);
  border-radius: 5px;
  font-weight: 600;
  display: flex;
  height: fit-content;
  line-height: 26px;
  .percent {
    color: #0cf520;
    margin-left: 10px;
    font-weight: 700;
  }
}

@media (max-width: 768px) {
  .box-interest {
    padding: 4px 8px;
  }
}

@media (max-width: 425px) {
  .content-nav {
    margin-left: 10px !important;
  }
  .box-interest {
    padding: 3px 10px;
    font-size: 12px !important;
    display: block;
    line-height: 14px;
    text-align: center;
    .percent {
      margin-left: 2px;
      margin-top: 2px;
    }
  }
}

// Token balance
.token-block {
  height: 100%;
  display: flex;
  align-items: center;
  .token-item {
    padding: 6px 12px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $main-text-block;
    background: $main-bg-block;
    @include media-breakpoint-down(md) {
      padding: 8px;
    }
  }
  .token-balance {
    white-space: nowrap;
  }
}

.ring-wrapper {
  position: relative;
  img {
    -webkit-animation: ring-img-anim 3s infinite ease-in-out;
    -moz-animation: ring-img-anim 3s infinite ease-in-out;
    -ms-animation: ring-img-anim 3s infinite ease-in-out;
    -o-animation: ring-img-anim 3s infinite ease-in-out;
    animation: ring-img-anim 3s infinite ease-in-out;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    animation-delay: 3s;
  }
  .new-message {
    position: absolute;
    top: -4px;
    right: -2px;
    width: 10px;
    height: 10px;
    display: block;
    background-color: rgb(0, 226, 113);
    border-radius: 50%;
  }
}
@-webkit-keyframes ring-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  5% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  15% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  25% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes ring-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  5% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  15% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  25% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
@keyframes ring-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  5% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  10% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  15% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }
  25% {
    transform: rotate(0) scale(1) skew(1deg);
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}
